body {
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
		"Helvetica Neue", Arial, sans-serif;
	line-height: 1.5;
}

.logsModal > .modal-dialog > .modal-content {
	width: 1000px;
}

.layout_border {
	border: 2px dashed green;
}

.playlist_modal > .modal-dialog > .modal-content {
	width: 800px;
}

.showContent_modal > .modal-dialog > .modal-content {
	width: 700px;
}

.showContent_modal_img {
	width: 698px;
}

.modal-dialog {
	margin-top: 7%;
	display: table;
	margin-left: auto;
	margin-right: auto;
}

.top-5 {
	margin-top: -5%;
}

/* .list-group-item-action{
  color: white;
  cursor:pointer;
}

.list-group-item-action:hover{
  color: aqua;
}

.list-group .list-group-item {
background-color:darkslategray;
} */

.pac-container {
	z-index: 10001;
}

.all_inventory {
	height: 80vh;
	overflow-x: hidden;
}

.inventory_map_height {
	height: 80vh;
}

.playlist_height {
	height: 40vh;
}

.inventory_info {
	height: 80vh;
}

.Mloader {
	position: absolute !important;
	top: 40%;
	left: 50%;
}

.Nloader {
	position: absolute !important;
	top: 50%;
	left: 40%;
}

.Mcolor {
	color: #1e5ca7;
}

.bootstrap_table {
	width: 700px;
	overflow: auto;
}

.dsply {
	display: none !important;
}

.table.size {
	width: 100%;
}

.border_new {
	background: #d2d2d2;
}

label {
	color: #333 !important;
}

.text-white {
	color: white;
}

.auto_margin {
	margin-left: auto;
	margin-right: auto;
	display: inherit;
}

.auto_margin2 {
	margin-left: auto;
	margin-right: auto;
	display: block;
}

.center-align2 {
	display: table;
	margin-left: auto;
	margin-right: auto;
}

.center-align {
	display: inherit;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 10px;
}

.videothumbnail {
	max-height: 100px;
	max-width: 150px;
	margin-left: auto;
	margin-right: auto;
	display: block;
	object-fit: fill;
}

.rbc-month-view {
	display: initial;
}

.rbc-agenda-view {
	display: table;
}

.fcolor-black {
	color: #000;
}

.custom-text-color {
	background: white;
	color: #1f5ca8;
	box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.vertical-align {
	position: relative;
	top: 15%;
}

.custom-form {
	background: white;
	border: 4px solid #444a54;
	box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.input-css {
	width: 250px;
	box-sizing: border-box;
	height: 35px;
	border: 1px solid #63c2de;
	border-left: hidden;
	border-right: hidden;
	border-top: hidden;
	font-size: 14px;
	background-color: white;
	background-image: url(../img/searchicon.png);
	background-position: 10px 10px;
	background-repeat: no-repeat;
	padding: 12px 20px 12px 40px;
	-webkit-transition: width 0.4s ease-in-out;
	transition: width 0.4s ease-in-out;
}

.input-css:focus {
	width: 100%;
}

.float-right {
	float: right;
}

.bg {
	background: url(../img/marker.png);
}

.m-10 {
	margin: 10px;
}

.ml-25vw {
	margin-left: 25vw;
}

.padding_zero {
	padding: 0;
}

.mt--10 {
	margin-top: -10px;
}

.mrgn {
	margin-bottom: 0px;
}

.mt-0 {
	margin-top: 0;
}

.mt-10 {
	margin-top: 10px;
}

.mt-15 {
	margin-top: 15p;
}

.mt-20 {
	margin-top: 20px;
}

.mt-22 {
	margin-top: 22px;
}

.mt-25 {
	margin-top: 25px !important;
}

.mt-35 {
	margin-top: 35px;
}

.mt-80 {
	margin-top: 80px;
}

.ml-20 {
	margin-left: 20px;
}

.mr-5 {
	margin-right: 5px;
}

.mr-15 {
	margin-right: 15px;
}

.mr-30 {
	margin-right: 30px;
}

.mb-10 {
	margin-bottom: 10px;
}

.mb-20 {
	margin-bottom: 20px;
}

.mb-30 {
	margin-bottom: 30px;
}

.mtb-0 {
	margin-top: 0;
	margin-bottom: 0;
}

.mtb-20 {
	margin: 20px 0 20px;
}

.p-10 {
	padding: 10px;
}

.pb-5 {
	padding-bottom: 5px;
}

.pb-10 {
	padding-bottom: 10px;
}

.pt-10 {
	padding-top: 10px;
}

.pr-5 {
	padding-right: 5px;
}

.inline {
	margin-top: 0px;
}

.display-inline {
	display: -webkit-inline-box;
}

.card {
	display: flex;
}

.custom-card {
	display: inline-flex;
	position: relative;
	width: 100%;
	margin-bottom: 1.5rem;
	color: white;
	box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.custom-card2 {
	display: inline-flex;
	position: relative;
	margin-bottom: 1.5rem;
	color: white;
	box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.bg-image {
	background: url(../img/bg.jpg);
	height: 100vh;
}

.position-fixed {
	position: fixed;
}

.footer {
	position: absolute;
	bottom: 0;
	width: 100%;
	height: 100px;
	background-color: #f5f5f5;
}

.max-width_height-150 {
	max-width: 200px;
	max-height: 200px;
}

header.navbar .navbar-brand {
	display: inline-block;
	width: 155px;
	height: 50px;
	border-bottom: none;
}

.pointer {
	cursor: pointer;
}

.btn {
	border-radius: 0;
}

.card-block {
	flex: 1 1 auto;
	padding: 2rem;
}

.object-fit-cover {
	object-fit: cover;
}

.thumbnail > img,
.thumbnail a > img {
	margin-right: auto;
	margin-left: auto;
	width: 100px;
	height: 100px;
}

.mfooter {
	position: inherit;
	margin: 15px 0;
	bottom: 0;
	width: 100%;
	height: 300px;
	overflow-y: auto;
	background-color: white;
}

.mfooter .first-child:first-child {
	background: lightseagreen;
	color: white;
}

.table-full-width {
	width: 100%;
}

.custom-thumbnail {
	display: block;
	max-width: 100px;
	height: 100px;
	margin-left: auto;
	margin-right: auto;
}

.uplaoded-file {
	color: white;
	font-style: italic;
	font-weight: 600;
}

.max-width550 {
	max-width: 550px;
}

.bg-000 {
	background: #2d89ef;
}

/* .panel {
  background-color: #b6ebfb;
}
.panel-color {
    border-color: #4DBD74;
}
.panel-color > .panel-heading {
    color: #ffffff;
    background-color: #4DBD74;
    border-color: #4DBD74;
} */

.responsive100pcnt {
	background-size: 100% 100%;
	height: 100%;
	width: 100%;
}

.rbc-toolbar {
	background-color: #3275ad;
	color: white;
	font-weight: 500;
}

.rbc-btn-group {
	display: inline-block;
	white-space: nowrap;
	background-color: #d9edf7;
}

.downexcel {
	font-weight: 500;
	font-size: medium;
	border-bottom: 2px solid lightslategray;
	border-radius: 10px;
	padding: 5px;
	float: right;
}

.refreshlog {
	font-weight: 500;
	background: #d9edf7;
	font-size: medium;
	border-bottom: 2px solid lightslategray;
	border-radius: 10px;
	color: #337ab7;
}

.hovereffect:hover h3 {
	color: darkgreen;
}

.hovereffect:hover img {
	opacity: 1;
	-webkit-animation: flash 1.5s;
	animation: flash 1.5s;
}

@-webkit-keyframes flash {
	0% {
		opacity: 0.2;
	}
	100% {
		opacity: 1;
	}
}

@keyframes flash {
	0% {
		opacity: 0.2;
	}
	100% {
		opacity: 1;
	}
}

.appimg {
	margin-left: auto;
	margin-right: align-self;
}

.w20em {
	width: 20em;
}

.bgcolor1 {
	background: aliceblue;
}

.bgcolor2 {
	background: #d9edf7;
}

@media only screen and (min-width: 768px) {
	#app {
		overflow: hidden;
	}
}

@media only screen and (max-width: 768px) {
	.custom-form {
		margin: 20px;
	}
}

@media only screen and (min-height: 200px) {
	.login_background {
		height: 100vh;
	}
}

.login_background {
	background-image: url(../img/bg.jpg);
	background-size: 100% 100%;
}

@media only screen and (max-width: 1024px) {
	.dashboard .col-md-6 {
		flex: initial;
		max-width: 100%;
		width: 100%;
	}
	.login-page .col-md-4 {
		flex: 0 0 33%;
		max-width: 33%;
	}
}

@media only screen and (max-width: 768px) {
	.table-width {
		overflow: scroll;
		max-width: 710px;
	}
	.mfooter {
		overflow: scroll;
		max-width: 710px;
	}
	.display-none {
		display: none;
	}
	.login-page .col-md-4 {
		flex: 0 0 100%;
		max-width: 95%;
	}
	.dashboard .col-md-6 {
		flex: initial;
		max-width: initial;
	}
	.login_background {
		overflow-y: auto;
	}
}

@media only screen and (max-width: 700px) {
	.table-width {
		overflow: scroll;
		max-width: 640px;
	}
	.thumbnail > img,
	.thumbnail a > img {
		margin-right: auto;
		margin-left: auto;
		width: auto;
		height: auto;
	}
}

@media only screen and (max-width: 600px) {
	.table-width {
		overflow: scroll;
		max-width: 500px;
	}
	.thumbnail > img,
	.thumbnail a > img {
		margin-right: auto;
		margin-left: auto;
		width: auto;
		height: auto;
	}
}

@media only screen and (max-width: 500px) {
	.table-width {
		overflow: scroll;
		max-width: 400px;
	}
}

@media only screen and (max-width: 400px) {
	.table-width {
		overflow: scroll;
		max-width: 300px;
	}
}

/* ::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 10px;
  overflow-y: scroll;
}

::-webkit-scrollbar-track {
  background-color: white;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: lightskyblue;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
} */

.screen_shot_modal {
	width: 800px;
}

.Clock {
	padding: 5px;
	margin-top: 15px;
	margin-left: auto;
	margin-right: auto;
}

.btn-margin-content-library {
	margin: 20px;
}

.timestamp_class {
	background-color: yellow;
	color: black;
}

.twitter_modal {
	height: 600px;
	overflow: hidden;
}

.places_auto_complete {
	z-index: 1;
	margin-bottom: 50px;
}

.modal-item-fixed-height {
	height: 150px;
	overflow: scroll;
}

.thumbnail > img {
	margin-right: auto;
	margin-left: auto;
	/* height: 200px; */
	width: auto;
	display: block;
}

.playlist-gallery-scroll {
	height: 70vh;
	overflow-y: scroll;
}

.playlist-choosen-scroll {
	overflow-y: scroll;
}

.hoc-root {
	display: flex;
	height: 400px;
	box-sizing: border-box;
	flex-direction: column;
}

.invalid-feedback {
	color: #d63031;
}

.react-bs-table table td,
.react-bs-table table th {
	overflow: hidden;
	white-space: normal;
	text-overflow: ellipsis;
}

td {
	word-wrap: break-word;
}

/* Remove this if anything happens */

div {
	word-wrap: break-word;
}
